<template>
  <div class="main_container">
    <div class="col-12">
      <span>Données Historiques ></span>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Importation de données historiques
          </div>
        </div>
      </div>
    </div>
    <div class="inner_container">
      <div class="table_action">
        <div class="div_left"></div>
        <div class="div_left">
          <!-- <a class="btn btn-blue"
             :href="baseUrl + marches.url"
             download>
            Exporter
          </a> -->
        </div>
      </div>
      <div class="tab_holder">
        <button class="btn btn-blue">
          Importer Programme
        </button>
        <button class="btn btn-blue">
          Importer Marché
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
export default {
  name: "DonneesHistoriques",
  data: () => ({
    user: "",
    baseUrl: process.env.VUE_APP_UPLOAD,
    pIndex: 1,
    long: "",
    loaded: false,
    goodUrl: ""
  }),
  watch: {

  },
  created() {
  },
  computed: {
    ...mapGetters(["audits", "auditsErrors"])
  },
  methods: {
    ...mapActions(["Audits",]),
    ...mapMutations(["setAudits","setAuditsErrors"]),
  }
}
</script>
